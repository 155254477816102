<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-row :gutter="20" class="top_l">
					<el-col :span="4" :xs="24"><el-input v-model="module" clearable placeholder="请输入模块查询"></el-input></el-col>
					<el-col :span="4" :xs="24"><el-input v-model="usernames" clearable placeholder="请输入用户名查询"></el-input></el-col>
					<el-col :span="6" :xs="24">
						<el-date-picker
							v-model="valueDate"
							type="datetimerange"
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
							value-format="yyyy-MM-dd HH:mm:ss"
						></el-date-picker>
					</el-col>
					<el-col :span="2" :xs="24"><el-button type="primary" @click="jouShare">搜索</el-button></el-col>
				</el-row>
				<!-- table 表格 -->
				<el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }" border stripe>
					<el-table-column type="index" label="序号" align="center" width="70"></el-table-column>
					<el-table-column prop="username" label="用户账号"></el-table-column>
					<el-table-column prop="ip" label="IP"></el-table-column>
					<el-table-column prop="module" label="模块"></el-table-column>
					<el-table-column prop="description" label="描述"></el-table-column>
					<el-table-column prop="content" label="内容" width="500px"></el-table-column>
					<el-table-column prop="createDate" label="创建时间"></el-table-column>
					<el-table-column prop="updateDate" label="更新时间"></el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
				<!-- 分页 -->
				<div class="pagin">
					<el-pagination
						:small="true"
						v-model:currentPage="currentPage"
						v-model:page-size="pageSize"
						:page-sizes="[10, 20, 50, 100]"
						background
						layout="total, sizes, prev, pager, next, jumper"
						:total="+totals"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
					/>
				</div>
			</el-card>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'journal',
	data() {
		return {
			currentPage: 1,
			pageSize: 10,
			tableData: [],
			totals: null,
			module: '',
			usernames: '',
			valueDate: '',
			serloading: false,
			emptyText: '暂无数据'
		};
	},
	created() {
		// 获取操作日志列表
		this.operationLog();
	},
	methods: {
		// 获取操作日志列表
		operationLog() {
			this.serloading = true;
			this.$HTTP.post(
				'log/list',
				{
					pageNumber: this.currentPage,
					pageSize: this.pageSize,
					module: this.module,
					usernames: this.usernames,
					startDate: this.valueDate[0],
					endDate: this.valueDate[1]
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableData = response.data.data.records;
							this.totals = response.data.data.total;
							this.serloading = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 搜索
		jouShare() {
			this.serloading = true;
			this.currentPage = 1;
			if (!this.valueDate) {
				this.valueDate = '';
			}
			// 获取操作日志列表
			this.operationLog();
		},
		// 分页
		handleSizeChange(val) {
			this.currentPage = 1;
			this.pageSize = val;
			// 获取操作日志列表
			this.operationLog();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			// 获取操作日志列表
			this.operationLog();
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
